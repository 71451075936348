import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Heart from '../../img/icons/Heart/svg/SPK-Heart';
import SearchWidget from './SearchWidget/SearchWidget';
import { browserName, browserVersion } from 'react-device-detect';
import CallOrText from './callOrText';
import HolidayIcon from './holidayIcon';
// #endregion Functions

export default function Header() {
    let [isMobile, setMobile] = useState(true);
    let [mobileMenuShown, setMobileMenuShown] = useState(false);
    let [browserAgent, setBrowserAgent] = useState('');
    let [outdatedMsg, setOutdatedMsg] = useState(false);
    let [showToTop, setShowToTop] = useState(false);
    const [callOrText, setCallOrText] = useState(false);
    const [scrolling, setScrolling] = useState(false);

    useEffect(() => {
        handleSizeConversion();

        setBrowserAgent(browserName);
        switch (browserAgent) {
            case ("Edge" && browserVersion < 31) ||
                ("Chrome" && browserVersion < 31) ||
                ("Safari" && browserVersion < 14) ||
                ("Firefox" && browserVersion < 65):
                setOutdatedMsg(true);
                break;
            default:
                console.info(browserName, browserVersion, "does not require update.");
                break;
        }

        window.addEventListener('scroll', () => {
            window.pageYOffset > 200 ? setScrolling(true) : setScrolling(false);
        });
        // eslint-disable-next-line
    }, []);

    window.addEventListener('resize', () => { handleSizeConversion(); });

    window.addEventListener("scroll", () => {
        window.scrollY >= window.innerHeight ? setShowToTop(true) : setShowToTop(false);
    });

    function handleSizeConversion() {
        window.innerWidth < 992 ? setMobile(true) : setMobile(false);
    }

    function toTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    return (
        <>
            <div id="Top-header">
                <div className="container">
                    <div className="phone-box">
                        <span className="iconify-inline" data-icon="bxs:phone-call"></span>
                        <a href="tel:+14238001418" rel='noreferrer'> (423) 800-1418</a>
                    </div>
                    { !isMobile &&
                        <div className="announcement">
                            <HolidayIcon />
                            <p>Serving the <iconify-icon inline icon="ci:location"></iconify-icon> <strong>Chattanooga, TN</strong> area!</p>
                        </div>
                    }
                    <div className="options-box d-none">
                        {/*  <Link to=""><span className="iconify-inline" data-icon="dashicons:money-alt"></span> Make Payment</Link>
                         <Link to=""><span className="iconify-inline" data-icon="carbon:user-avatar"></span> Login</Link> */}
                    </div>
                </div>
            </div>

            <div id="Search">
                <div id="Search-Bar" className="container">

                    <div className='socials-box'>
                        <a title="Visit our Google Page" target="_blank" rel='noreferrer' href="https://goo.gl/maps/3cRvKqbEz66mNaho9"><span
                            className="iconify" data-icon="fa6-brands:google"></span></a>
                        <a title="Visit our Facebook Page" target="_blank" rel='noreferrer'
                            href="https://www.facebook.com/Sparkleancleaningservicesofchattanooga/"><span className="iconify"
                                data-icon="fa6-brands:facebook-f"></span></a>
                        <a title="Visit our TikTok Feed" target="_blank" rel='noreferrer'
                            href="https://www.tiktok.com/@sparklean.services?lang=en"><span className="iconify"
                                data-icon="fa6-brands:tiktok"></span></a>
                        <a title="Follow our Snapchat" target="_blank" rel='noreferrer'
                            href="https://www.snapchat.com/add/sparklean1418?share_id=NFYkOiyhYn0&locale=en-US"><span
                                className="iconify" data-icon="fa:snapchat-ghost"></span></a>
                    </div>
                    <SearchWidget />
                </div>
            </div>


            <header id="Header">
                <div className="container barrier">
                    <Link to="/" className="logo-box">
                        <div className="img-wrap">
                            <Heart className='heart' />
                        </div>
                        <h1 className="header-logo-txt">SparKlean</h1>
                    </Link>

                    { isMobile &&
                        <div className='mobileButtons'>
                            <button onClick={ () => { setCallOrText(prev => !prev); } }><span className="iconify" data-icon="mdi:phone-message" title='Call or Text' name='Call or Text'></span></button>
                            <button id="burger" className="d-block" onClick={ () => { setMobileMenuShown(true); } } title='Show Mobile Navigation' name='Show Mobile Navigation'>
                                <div className="line top"></div>
                                <div className="line mid"></div>
                                <div className="line bott"></div>
                            </button>
                        </div>
                    }

                    <nav id="nav" className={ `${isMobile ? 'mobile-nav' : ''} ${mobileMenuShown ? 'mobile-open' : ''}` }>
                        <Link onClick={ () => { setMobileMenuShown(false); } } to="/" className="nav-item" title='Home'>Home</Link>
                        <Link onClick={ () => { setMobileMenuShown(false); } } to="/gallery" className="nav-item" title='Gallery'>Gallery</Link>
                        <div className="dropdown">
                            <Link to="/#Services" className="nav-item dropdown-toggle"
                                id="dd-services" data-bs-toggle="dropdown" aria-expanded="false" title='Our Services'>Services</Link>
                            <ul className="dropdown-menu" aria-labelledby="dd-services">
                                <li> <Link onClick={ () => { setMobileMenuShown(false); } } to="/residential" className="dropdown-item" title='Residential'><span
                                    className="iconify icon" data-icon="ci:home-alt-outline"></span> Residential</Link></li>
                                <li> <Link onClick={ () => { setMobileMenuShown(false); } } to="/commercial" className="dropdown-item" title='Commercial'><span
                                    className="iconify-inline" data-icon="ep:office-building"></span> Commercial</Link></li>
                                <li> <Link onClick={ () => { setMobileMenuShown(false); } } to="/rentals" className="dropdown-item" title='Rentals'><span
                                    className="iconify icon" data-icon="jam:airbnb"></span> Rentals</Link></li>
                                <li> <Link onClick={ () => { setMobileMenuShown(false); } } to="/additional" className="dropdown-item" title='Additional'><span className="iconify" data-icon="ri:service-line"></span> Additional</Link></li>
                            </ul>
                        </div>
                        <HashLink onClick={ () => { setMobileMenuShown(false); } } to="/#FAQ" className="nav-item" title='Frequently Asked Questions'>FAQs</HashLink>
                        <Link onClick={ () => { setMobileMenuShown(false); } } to="/contact" className="nav-item outline" title='Contact Us'>Contact</Link>
                        <a href="tel:+4238001418" className={ isMobile ? 'nav-item phone' : 'd-none' }><span className="iconify-inline" data-icon="bxs:phone-call"></span> Call Now!</a>

                        <button id="Nav-X-Btn" className="nav-item" name="Nav-X-Btn" onClick={ () => { setMobileMenuShown(false); } } title='Close mobile navigation'><span
                            className="iconify" data-icon="emojione-v1:heavy-multiplication-x"></span></button>
                    </nav>
                </div>
                <div id="Phone-Strip" className={ scrolling ? 'show' : '' }>
                    <div className="container">
                        <a href="tel:+14238001418"><span className="iconify-inline" data-icon="bxs:phone-call" ></span> (423) 800-1418</a>
                    </div>
                </div>
                {
                    showToTop &&
                    <button id="to-top" onClick={ toTop } title='Scroll Back To Top'>
                        <span className="iconify" data-icon="bxs:up-arrow-circle"></span>
                    </button>
                }
            </header>

            { callOrText && isMobile && <CallOrText /> }

            <noscript className='alert alert-danger text-center' title='No Javascript'>
                <p>Our site relies heavily on the use of Javascript.</p>
                <p>Please enable it, or switch to a different browser that has it enabled.</p>
                <a href={ `https://www.whatismybrowser.com/guides/how-to-enable-javascript/${browserName.toLowerCase()}` } target='_blank' rel='noreferrer'>Learn More</a>
            </noscript>

            { outdatedMsg &&
                <div id="UpdateMessage" className="alert alert-danger" role="alert" title="Outdated Browser">
                    <p id="brwsrMsg"><strong><span className="iconify-inline" data-icon="eva:alert-triangle-fill"></span> Outdated
                        Browser</strong></p>
                    <p>It looks like you're using an older version of { browserAgent }. Because of this, our website may not function properly.</p>
                    <div>
                        <p>
                            <a id="browserUpdateAnchor" href="https://browser-update.org/update-browser.html" rel='noreferrer' title="Update Your Browser" target="_blank"><strong>Update Now</strong></a> or <button onClick={ () => { setOutdatedMsg(false); } } title="Close this message">Dismiss</button>
                        </p>
                    </div>
                </div>
            }
        </>
    );
};