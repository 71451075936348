import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ErrorBoundary from './components/dev/errorBoundary';
// Components & Style
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import './styles/index/index.css';
// Pages
import Home from './routes/home';
import Gallery from './routes/gallery';
import Contact from './routes/contact';
import Residential from './routes/services/residential';
import Commercial from './routes/services/commercial';
import Biohazard from './routes/services/biohazard';
import Rentals from './routes/services/rentals';
import Additional from './routes/services/additional';
import CovidPolicy from './routes/policy/covid-19';
import PrivacyPolicy from './routes/policy/privacy';
import TermsOfUse from './routes/policy/terms';
import NotFound from './routes/404';
import Apply from './routes/apply';
import Thanks from './routes/thanks';

import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <Router>

          <Header />

          <Routes>
            <Route path='/' element={ <Home /> } />
            <Route path='/gallery' element={ <Gallery /> } />
            <Route path='/residential' element={ <Residential /> } />
            <Route path='/commercial' element={ <Commercial /> } />
            <Route path='/biohazard' element={ <Biohazard /> } />
            <Route path='/rentals' element={ <Rentals /> } />
            <Route path='/additional' element={ <Additional /> } />

            <Route path='/contact' element={ <Contact /> } />
            <Route path='/apply' element={ <Apply /> } />
            <Route path='/thanks' element={ <Thanks /> } />

            <Route path='/policy/covid-19' element={ <CovidPolicy /> } />
            <Route path='/policy/privacy' element={ <PrivacyPolicy /> } />
            <Route path='/policy/terms' element={ <TermsOfUse /> } />

            <Route path='*' element={ <NotFound /> } />
          </Routes>

          <Footer />

        </Router>
      </HelmetProvider>
    </ErrorBoundary >
  );
};

export default App;