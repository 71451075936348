import React from 'react';
import { Link } from 'react-router-dom';
// Images
import TennesseeLogo from '../../img/icons/tennessee-stars.png';
import Heart from '../../img/icons/Heart/svg/SPK-Heart';

export default function Footer() {
    return (
        <footer>
            <div className="container links">
                <div className="box">
                    <Link to="/" className="logo-box">
                        <Heart />
                        <h2>SparKlean<span>&trade;</span></h2>
                    </Link>
                    <p className="my-1">A Tennessee <img className='TN' loading="lazy" src={ TennesseeLogo }
                        alt="Tennessee Icon" aria-hidden="true" /> Company</p>
                    <a target="_blank" rel='noreferrer' className='mb-2'
                        href="https://goo.gl/maps/EbYmTqxyGsdEYcAy7"> 1208 Pointe Centre Dr, Suite 217 <br /> Chattanooga,
                        TN 37421</a>
                    <a href="tel:+14238001418" className="mb-2" style={ { fontWeight: 400, fontSize: '24px' } }><span className="iconify-inline" data-icon="bxs:phone-call"></span> (423) 800-1418</a>
                    <div className="socials-box">
                        <a title="Visit our Google Page" target="_blank" rel='referrer' href="https://goo.gl/maps/3cRvKqbEz66mNaho9"><span
                            className="iconify" data-icon="fa6-brands:google"></span></a>
                        <a title="Visit our Facebook Page" target="_blank" rel='noreferrer'
                            href="https://www.facebook.com/Sparkleancleaningservicesofchattanooga/"><span className="iconify"
                                data-icon="fa6-brands:facebook-f"></span></a>
                        <a title="Visit our TikTok Feed" target="_blank" rel='noreferrer'
                            href="https://www.tiktok.com/@sparklean.services?lang=en"><span className="iconify"
                                data-icon="fa6-brands:tiktok"></span></a>
                        <a title="Follow our Snapchat" target="_blank" rel='noreferrer'
                            href="https://www.snapchat.com/add/sparklean1418?share_id=NFYkOiyhYn0&locale=en-US"><span
                                className="iconify" data-icon="fa:snapchat-ghost"></span></a>
                    </div>
                </div>
                <div className="box">
                    <h2>About</h2>
                    <Link to="/gallery">Gallery</Link>
                    <Link className='d-none' to="/apply">Apply Now</Link>
                    <a target="_blank" rel='noreferrer' href="https://goo.gl/maps/3cRvKqbEz66mNaho9">Google Listing</a>
                    <a target="_blank" rel='noreferrer'
                        href="https://www.bbb.org/us/tn/chattanooga/profile/cleaning-services/sparklean-cleaning-services-0483-40131244">BBB
                        Listing</a>
                </div>
                <div className="box">
                    <h2>Services</h2>
                    <Link to="/residential">Residential</Link>
                    <Link to="/commercial">Commercial & Business</Link>
                    <Link to="/rentals">Airbnb/Vacation Rentals</Link>
                    <Link to="/additional">Additional Services</Link>
                </div>
            </div>
            <div className="copyright-box container">
                <p>&copy; { new Date().getFullYear() } SparKlean. All Rights Reserved.</p>
                <div className="legal-box">
                    <Link to="/policy/terms">Terms of Use</Link>
                    <Link to="/policy/privacy">Privacy Policy</Link>
                </div>
            </div>
        </footer>
    );
}
