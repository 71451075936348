import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import '../../styles/services/services.css';
import IMG from '../../img/backgrounds/service-backgrounds/additional.jpg';
import ReadyToGetKleaning from '../../components/Footer/readyToGetKleaning';

export default function Additional() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <meta name="description" content="SparKlean's additional services include power washing an, and more!" />
                <meta name="keywords" content="cleaning service, construction, carpet, hot tub spa, roof cleaning, gutter cleaning, junk removal, garage cleaning, power washing, pest treatment" />
                <meta property="og:title" content="SparKlean | Additional Services" />
                <meta property="og:description" content="SparKlean's additional services include power washing and pest treatment, and more!" />
                <meta property="og:url" content="https://sparklean.services/additional" />
                <meta property="og:type" content="article" />
                <title>SparKlean | Additional Services</title>
            </Helmet>
            <section id="Service-Hero" data-service='additional'>
                <div className="container"
                    data-aos="fade-left">
                    <h1><span className="iconify icon" data-icon="ri:service-line"></span> Additional Services</h1>
                    <h2>All-In-One Solutions</h2>
                </div>
            </section>

            <section id="Service">
                <div className="container">
                    <div className="desc"
                        data-aos="fade-left">
                        <img
                            data-aos="fade-right"
                            data-aos-delay="200"
                            src={ IMG }
                            alt="Commercial Cleaning" />
                        <div className="cta">
                            <h2>Have another project?</h2>
                            <p>We have a variety of contractors at the ready to tackle any and all of your cleaning needs. If a service you're interested in is not listed below, <Link to='/contact'>reach out to us</Link> and we'll explore all your options!</p>
                            <ul className="selling-points">
                                <li>Quotes are 100% <strong>FREE</strong></li>
                                <li>100% Satisfaction Guarantee</li>
                                <li>Fully-customizable cleaning packages</li>
                            </ul>
                            <Link className="SPK-CTA"
                                to="/contact">Get a FREE Quote</Link>
                        </div>
                    </div>
                    <ul className="checklist" data-aos="zoom-in">
                        <li>
                            <iconify-icon icon="entypo:water"></iconify-icon>
                            <p>Power Washing</p>
                        </li>
                        <li>
                            <iconify-icon icon="icon-park-outline:fence-one"></iconify-icon>
                            <p>Patio Cleaning</p>
                        </li>
                        <li>
                            <iconify-icon icon="mdi:bookshelf"></iconify-icon>
                            <p>Organize & De-clutter</p>
                        </li>
                        <li>
                            <iconify-icon icon="ic:round-house"></iconify-icon>
                            <p>Rentals</p>
                        </li>
                        <li>
                            <iconify-icon icon="mdi:rv-truck"></iconify-icon>
                            <p>RV Cleaning</p>
                        </li>
                        <li>
                            <iconify-icon icon="clarity:boat-solid"></iconify-icon>
                            <p>Boat Cleaning</p>
                        </li>
                        <li>
                            <iconify-icon icon="mdi:house-swap"></iconify-icon>
                            <p>Move-Ins/Outs</p>
                        </li>
                        <li>
                            <iconify-icon icon="game-icons:party-flags"></iconify-icon>
                            <p>Event/After Party Cleanup</p>
                        </li>
                        <li>
                            <iconify-icon icon="emojione-monotone:construction"></iconify-icon>
                            <p>Post-Construction Clean</p>
                        </li>
                        <li><iconify-icon icon="icon-park-outline:bubble-chart"></iconify-icon>
                            <p>Carpet Shampooing & Extraction</p>
                        </li>
                        <li>
                            <iconify-icon icon="lucide:air-vent"></iconify-icon>
                            <p>Air Ducts</p>
                        </li>
                        <li>
                            <iconify-icon icon="fa-solid:hot-tub"></iconify-icon>
                            <p>Hot Tub Cleaning & Treatment</p>
                        </li>
                        <li><iconify-icon icon="iconoir:garage"></iconify-icon>
                            <p>Garage Cleaning</p>
                        </li>
                        <li>
                            <p>and more...</p>
                        </li>
                    </ul>

                    <div className="container text-center mt-5 d-none">
                        <h2>Got a project?</h2>
                        <p>Have one of our personal contractors assist you!</p>
                        <p>Please <Link to='/contact'>fill out our contact form</Link> and our staff will further assist you.</p>
                    </div>

                    <div className="checklist green d-none">
                        <li><iconify-icon icon="bxs:paint"></iconify-icon>
                            <p>Wood Re-finishing</p>
                        </li>
                        <li><iconify-icon icon="ic:round-construction"></iconify-icon>
                            <p>Drywall Repair</p>
                        </li>
                        <li><iconify-icon icon="ic:baseline-grass"></iconify-icon>
                            <p>Yard Maintenance</p>
                        </li>
                        <li><iconify-icon icon="ic:sharp-electric-bolt"></iconify-icon>
                            <p>Electrical</p>
                        </li>
                        <li><iconify-icon icon="ic:baseline-plumbing"></iconify-icon>
                            <p>Plumbing</p>
                        </li>
                        <li><iconify-icon icon="icon-park-outline:floor-tile"></iconify-icon>
                            <p>Flooring</p>
                        </li>
                    </div>
                </div>
            </section>

            <ReadyToGetKleaning />
        </>
    );
}
