import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export default function CovidPolicy() {
    return (
        <>
            <Helmet>
                <meta name="description" content="View our statement about viruses and Covid-19" />
                <meta name="keywords" content="virus covid clean sanitation disinfecting policy statement" />
                <meta name='robots' content='noindex' />
                <meta property="og:title" content="SparKlean | Covid-19 Statement" />
                <meta property="og:description" content="View our statement about viruses and Covid-19" />
                <meta property="og:url" content="https://sparklean.services/policy/covid-19" />
                <meta property="og:type" content="article" />
                <title>SparKlean | Covid-19</title>
            </Helmet>
            <section className="Policy container">
                <h1 className="my-4">Covid-19 & Virus&nbsp;Defense</h1>
                <p>With the rapid spread of Coronavirus (COVID-19) and seasonal viruses such as the flu, our number one priority is the safety of our clients and staff. We closely monitor the latest news for any global and public health updates, so that we can confidently follow any new cleaning guidelines or protocols.</p>
                <p>To protect the health and safety of our staff and prevent any cross contamination, our team  wears Personal Protective Equipment (PPE) at all times. All of our equipment and supplies are thoroughly sanitized between every cleaning.</p>
                <p>Our cleaning process will sanitize AND disinfect. Our products are eco safe and eliminate many types of viruses including Covid-19. We also give a detailed report to all new clients of this cleaning process.</p>
                <p>If you become sick with a virus, including Covid 19, our team is well equipped and trained to decontaminate your home or property. We are also equipped to eliminate any airborne particles.</p>
                <p>You can count on us to ensure your property is free and clear of any germ-causing bacteria that could lead to the contraction of Covid-19 or other illnesses.</p>
                <p><br />For questions about our policies and procedures, <Link style={ { display: 'inline' } } to="/contact">send&nbsp;us&nbsp;a&nbsp;message</Link> or call us at <a className="d-inline" href="tel:+14238001418">(423)&nbsp;800-1418</a>. We look forward to helping you, and wish you and your family well.</p>
                <div className="signature">
                    <p className="mb-3">Sincerely,</p>
                    <p className="mb-2" style={ { fontWeight: 500 } }>Felicia Boyes</p>
                    <p>SparKlean Cleaning Services</p>
                    <p>Owner</p>
                </div>
            </section>
        </>
    );
}
