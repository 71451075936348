import React, { useEffect, useState } from 'react'

export default function HolidayIcon() {
    const [holiday, setHoliday] = useState({ title: '', icon: '' });

    useEffect(() => {
        const HOLIDAY = getHolidayIcon();
        setHoliday(HOLIDAY);
    }, []);

    function getHolidayIcon() {
        const DATE = new Date();
        var month = DATE.getMonth() + 1;
        var day = DATE.getDate();

        switch (month) {
            case 1:
                return {
                    icon: 'twemoji:party-popper',
                    title: `Happy ${DATE.getFullYear()}!`
                };

            case 2: //February
                if (day >= 10 && day <= 14) {
                    return {
                        icon: 'twemoji:growing-heart',
                        title: "Happy Valentine's Day!"
                    };
                }
                return {
                    icon: 'twemoji:flag-ethiopia',
                    title: 'Honoring Black History Month'
                };

            case 3: //March
                if (day >= 14 && day <= 17) {
                    return {
                        icon: 'emojione:shamrock',
                        title: "Happy St Patrick's Day!"
                    };
                }
                return {
                    icon: 'noto:female-sign',
                    title: "Celebrating Women's History Month"
                };

            case 4: //April
                if (day < 16) {
                    return {
                        icon: 'fxemoji:bunnyside',
                        title: 'Happy Easter!'
                    };
                }
                else return {
                    icon: 'noto:umbrella-with-rain-drops',
                    title: 'April Showers Bring May Flowers!'
                };
            case 5: //May
                if (day < 6) {
                    return {
                        icon: 'fxemoji:partypopper',
                        title: 'Feliz Cinco de Mayo!'
                    };
                }
                if (day > 20) {
                    return {
                        icon: 'openmoji:flag-united-states',
                        title: `Memorial Day ${DATE.getFullYear()}`
                    };
                }
                return {
                    icon: 'emojione-v1:bouquet-of-flowers',
                    title: 'Happy Spring!'
                };

            case 6: //June
                if (day >= 17 && day <= 19) {
                    return {
                        icon: 'game-icons:fist',
                        title: 'Celebrating Juneteenth'
                    };
                }
                return {
                    icon: 'fluent-emoji-flat:rainbow',
                    title: 'Celebrating Pride Month'
                };

            case 7: //July
                if (day <= 5) {
                    return {
                        icon: 'emojione-v1:flag-for-united-states',
                        title: 'Happy 4th of July!'
                    };
                }
                return {
                    icon: 'noto:beach-with-umbrella',
                    title: 'Enjoy the Summer!'
                }
            case 8: //August
                return {
                    icon: 'noto:nerd-face',
                    title: "It's National Eye-Exam Month!"
                };

            case 9: //September
                if (day < 7) {
                    return {
                        icon: 'openmoji:flag-united-states',
                        title: 'Happy Labor Day!'
                    };
                }
                return {
                    icon: 'emojione-v1:reminder-ribbon',
                    title: `It's Blood Cancer Awareness Month`
                };

            case 10: //October
                if (day <= 9) {
                    return {
                        icon: 'twemoji:sailboat',
                        title: 'Happy Columbus Day!'
                    };
                }
                if (day >= 10 && day <= 24) {
                    return {
                        icon: 'emojione-v1:reminder-ribbon',
                        title: `It's Breast Cancer Awareness Month`
                    };
                }
                return {
                    icon: 'emojione:jack-o-lantern',
                    title: 'Happy Halloween!'
                };

            case 11: //November
                if (day <= 11) {
                    return {
                        icon: 'noto:military-medal',
                        title: "Honoring Veteran's Day"
                    };
                }
                return {
                    icon: 'emojione:turkey',
                    title: 'Happy Thanksgiving!'
                };

            case 12: //December
                if (day >= 26) {
                    return {
                        icon: 'twemoji:party-popper',
                        title: 'Happy New Year!'
                    };
                }
                if (day <= 20) {
                    return {
                        icon: 'fxemoji:christmastree',
                        title: 'Happy Holidays!'
                    }
                }
                return {
                    icon: 'fxemoji:christmastree',
                    title: 'Merry Christmas!'
                };
            default:
                break;
        }
    }
    return (
        <div id='HolidayIcon' aria-hidden='true'>
            <iconify-icon icon={ holiday.icon } aria-hidden='true' />
            <p aria-hidden='true'>{ holiday.title }</p>
        </div>
    )
}
