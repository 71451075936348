const TEAM = [
    {
        name: "Felicia Boyes",
        job: ["Owner & Founder", "Detail & Cleaning Specialist", "First Responder"],
        image: "/images/team/Felicia-3.jpg",
        website: "",
        socials: {
            facebook: "Sparkleancleaningservicesofchattanooga/",
            tiktok: "@sparklean.services?lang=en",
            snapchat: "sparklean1418?share_id=NFYkOiyhYn0&locale=en-US"
        }
    },
    {
        name: "Emma P.",
        job: ["Office Assistant"],
        image: "/images/team/Emma.jpg",
        website: "",
        socials: {
        }
    },
    {
        name: "Vance M.",
        job: ["Assistant", "First Responder"],
        image: "/images/team/Vance.jpg",
        website: "",
        socials: {
        }
    },
    // {
    //     name: "Courtney Holland",
    //     job: "Customer Support",
    //     image: "/images/team/Courtney-Holland.jpg",
    //     website: "",
    //     socials: {

    //     }
    // },
    {
        name: "Herman Owens",
        job: ["(Dr. H20)", "Chemist"],
        image: "/images/team/Herman-2.jpg",
        website: "",
        socials: {
            website: 'https://www.ochemacademy.com',
            tiktok: '@ochemacademy?lang=en'
        }
    },
    {
        name: "Amanda Cobb",
        job: "Designer",
        subtitle: "Southern Trend",
        image: "/images/team/Amanda-Cobb.jpg",
        website: "",
        socials: {
            facebook: "southerntrenddesigns",
            tiktok: '@southern_trend',
            website: "https://www.southerntrend.co",

        }
    },
    {
        name: "Erick Booth",
        job: "Web Developer",
        image: "/images/team/Erick-Booth.jpg",
        website: "",
        socials: {
            website: "https://www.erickbooth.com",
            linkedin: "erickbooth",
            github: "Erick-Booth"
            // facebook: "erick.booth.1",
            // twitter: "ErickBooth01",
        }
    },
];
export default TEAM;