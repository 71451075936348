import React, { useEffect, useState } from 'react';
import PAGES from '../../../scripts/searchPages';
import SearchResult from './SearchResult';

export default function SearchWidget() {

    let [query, setQuery] = useState('');
    let [showAlert, setShowAlert] = useState(false);
    let [results, setResults] = useState([]);

    useEffect(() => {
        if (query.length >= 2) {
            searchQuery(query);
        } else {
            setResults([]);
        }
    }, [query]);

    function searchQuery(query) {
        setResults([]);
        let stage = [];
        query = query.toLowerCase();
        console.clear();
        // Combs through pages, stage them by relevancy using a point system.
        PAGES.forEach(page => {
            if (!page.enabled) { return; }
            // RACK UP POINTS
            let points = 0;
            if (page.name.includes(query)) { points++; }

            let shortenedPageName = page.name.toLowerCase().replace(/\s+/g, '');
            if (shortenedPageName.indexOf(query) >= 0) { points += 2; }

            page.keywords.forEach(word => {
                if (word.indexOf(query) >= 0) { points++; } // has partial query
                if (word.includes(query)) { points++; } // has full query
            });

            page.points = points;
            if (points > 0) {
                stage.push(page);
            }
        });

        window.onscroll = () => {
            if (window.scrollY > 50) {
                setResults([]);
            }
        };

        // If stage has pages, only allow up to 5 results, sort them by points, then setResults
        if (stage.length > 0) {
            setResults(stage.slice(0, 4).sort((a, b) => a - b));
            setShowAlert(false);
        } else {
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 4000);
        }
    }

    return (
        <>
            <div className="search-container">
                { showAlert &&
                    <label id="Result-Alert" htmlFor='Search-Box'>No results ... Try a different
                        keyword!</label>
                }

                <input id="Search-Box" type="search" placeholder="Search our services..." maxLength={ 25 }
                    onChange={ (e) => { setQuery(e.target.value); } } onFocus={ () => { setQuery(''); } } contentEditable="true" data-gramm="false" data-gramm_editor="false"
                    data-enable-grammerly="false" />
                <span className="iconify-inline" data-icon="bx:search"></span>
            </div>

            {
                results.length > 0 &&
                <div id="Search-Results-Box" className='show' aria-label='Search Results Box' onBlur={ () => { setResults([]); } }>
                    {
                        results.map((page, i) => {
                            return <SearchResult page={ page } key={ i } tab={ i + 1 } />;
                        })
                    }

                </div>
            }

        </>
    );
}
