import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import '../styles/contact/contact.css';

export default function Contact() {
    let [activeCard, setActiveCard] = useState(1);
    let [firstName, setFirstName] = useState('');
    let [phone, setPhone] = useState('');
    let [email, setEmail] = useState('');
    let [address, setAddress] = useState('');
    let [message, setMessage] = useState('');
    let [subject, setSubject] = useState('Contact Form Submission');
    let [submitted, setSubmitted] = useState(false);

    let allValid = () => {
        if (firstName &&
            (phone.length >= 14 || email.length >= 5) &&
            email.includes("@") &&
            message.length <= 500 &&
            message.length > 5 &&
            !submitted) {
            return true;
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function formatPhone(number) {
        var cleaned = ('' + number).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return number;
    }

    function contactIsValid() {
        return (firstName && (((email.length >= 5) && (email.includes('@'))) || (phone.length >= 14))) ? true : false;
    }

    function handleSend(e) {
        e.preventDefault();
        document.activeElement.blur();
        window.scrollTo(0, 0);
        setSubmitted(true);
        try {
            document.getElementById("Contact-Form").submit();
        } catch (error) {
            console.error("Error when submitting:", error);
            setSubmitted(false);
            setActiveCard(1);
        }
    }

    return (
        <>
            <Helmet>
                <meta name="description" content="Contact SparKlean today for a FREE quote on residential, commercial, biohazard and other cleaning services!" />
                <meta name="keywords" content="contact, question, message, call, text, pay" />
                <meta property="og:title" content="SparKlean | Contact Us" />
                <meta property="og:description" content="Contact SparKlean today for a FREE quote on residential, commercial, biohazard and other cleaning services!" />
                <meta property="og:url" content="https://sparklean.services/contact" />
                <meta property="og:type" content="article" />
                <title>SparKlean | Contact Us</title>
            </Helmet>

            <div className="container text-center my-5">
                <h1>Contact Us</h1>
                {
                    !submitted &&
                    <p>We're&nbsp;excited&nbsp;to chat&nbsp;with&nbsp;you! Leave us a&nbsp;message&nbsp;below.</p>
                }
                <div className=" decorative-line"></div>
                {
                    submitted &&
                    <p style={ { animation: 'blink .5s alternate infinite' } }>Sending...</p>
                }
            </div>


            <div className='container'>
                <form id='Contact-Form' action="https://formsubmit.co/sparklean2013@gmail.com" method="POST" encType='multipart/form-data'
                    netlify='true'>
                    {/* Contact Info */ }
                    <div id="Card-1" className='form-card' data-active={ activeCard === 1 }>
                        <h1>Step 1<span>/3</span></h1>
                        <div className="card-row">
                            <div className='card-field'>
                                <label required htmlFor="firstName">First Name</label>
                                <input value={ firstName } required type="text" name="First&nbsp;Name" id="firstName" maxLength={ 20 } onInput={ (e) => { setFirstName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1).replace(" ", "")); } } />
                            </div>
                            <div className='card-field'>
                                <label htmlFor="lastName">Last Name <span>(optional)</span></label>
                                <input type="text" name="Last&nbsp;Name" id="lastName" maxLength={ 20 } />
                            </div>
                        </div>
                        <div className="card-row">
                            <div className='card-field'>
                                <label required htmlFor="email">Email</label>
                                <input value={ email } type="email" name="Email" id="email" placeholder='email@example.com' maxLength={ 50 } onInput={ (e) => { setEmail(e.target.value); } } />
                            </div>
                            <div className='card-field'>
                                <label required htmlFor="phone">Phone Number</label>
                                <input value={ phone } minLength={ 14 } maxLength={ 17 } type="tel" name="Phone" id="phone" onInput={ (e) => { setPhone(formatPhone(e.target.value)); } } placeholder='(  )   -' />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button type='button' disabled={ !contactIsValid() } className='btn btn-primary' onClick={ () => { setActiveCard(activeCard + 1); } }>Next</button>
                        </div>
                    </div>
                    {/* Address */ }
                    <div id="Card-2" className="form-card" data-active={ activeCard === 2 }>
                        <h1>Step 2<span>/3 (Optional)</span></h1>
                        <div className="card-row">
                            <div className='card-field'>
                                <label htmlFor="address">What is the address related to your message?</label>
                                <input value={ address } type="text" name="Address" id="address" placeholder='123 SparKlean Ave' maxLength={ 50 } onInput={ (e) => { setAddress(e.target.value); } } />
                            </div>
                        </div>
                        <div className="card-row">
                            <div className='card-field'>
                                <label htmlFor="City">City</label>
                                <input type="text" name='City' maxLength={ 20 } />
                            </div>
                            <div className='card-field'>
                                <label htmlFor="state">State</label>
                                <select name='State'>
                                    <option value="">-</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="GA">Georgia</option>
                                    <option value="AL">Alabama</option>
                                </select>
                            </div>
                            <div className='card-field' style={ { maxWidth: '.8rem' } }>
                                <label htmlFor="Zip">Zip Code</label>
                                <input type="text" name='Zip' maxLength={ 5 } />
                            </div>
                        </div>
                        <div className="card-row justify-content-end">
                            <button type='button' className='btn' onClick={ () => { setActiveCard(activeCard - 1); } }>Back</button>
                            <button type='button' className='btn btn-primary' onClick={ () => { setActiveCard(activeCard + 1); } }>
                                { address.length ? 'Next' : 'Skip' }
                            </button>
                        </div>
                    </div>
                    {/* Message */ }
                    <div id="Card-3" className="form-card" data-active={ activeCard === 3 }>
                        <h1>Step 3<span>/3</span></h1>
                        <div className='card-field'>
                            <label htmlFor="objective" className='mb-2'>How can we help you? (Select&nbsp;an&nbsp;option)</label>
                            <select name="Subject" id="objective" onChange={ (e) => { setSubject(e.target.value); } }>
                                <option value="Request a Free Quote">Request a Free Quote</option>
                                <option value="Question about Services">Question about Services</option>
                                <option value="Question about Payment">Question about Payment</option>
                                <option value="Question: Other">Other</option>
                            </select>
                            <div className="mt-4">
                                <label htmlFor="Uploads">Upload Images <span>(Optional)</span></label>
                                <input type="file" multiple name="Uploads" id='Uploads' accept='image/*,.pdf' />
                            </div>
                            <div style={ { position: 'relative' } } className='mt-4'>
                                <label required htmlFor="message">What is your message?</label>
                                <textarea required value={ message } name="Message" id="message" cols="30" rows="4" maxLength={ 500 } onInput={ (e) => { setMessage(e.target.value); } }></textarea>
                                <p className='counter'>{ message.length }/500</p>
                            </div>
                        </div>

                        <div>
                            <p style={ { fontSize: '11px', opacity: 0.75, margin: '5px 0', lineHeight: '110%' } }>By submitting this form, you are acknowledging and agreeing to the SparKlean <a href="https://sparklean.services/policy/terms" target='_blank' rel='noreferrer'>Terms of Use</a> and <a href="https://sparklean.services/policy/privacy" target='_blank' rel='noreferrer'>Privacy Policy</a>.</p>
                        </div>

                        <div className="card-row justify-content-end align-items-center">
                            <button type='button' className='btn' onClick={ () => { setActiveCard(activeCard - 1); } }>Back</button>
                            <button type='submit' id='Submit-Button' onClick={ (e) => { handleSend(e); } } disabled={ !allValid() } className='btn btn-primary'>Send <iconify-icon inline icon="bxs:paper-plane"></iconify-icon></button>
                        </div>
                    </div>
                    {/* Settings */ }
                    <input type="text" name="_honey" style={ { display: 'none' } }></input>
                    <input type="hidden" name="_template" value="box"></input>
                    <input type="hidden" name="_subject" value={ subject }></input>
                    <input type="hidden" name="_captcha" value="true"></input>
                    <input type="hidden" name="_next" value={ window.location.origin + `/thanks?name=${firstName}` }></input>
                </form>
            </div>
        </>
    );
}
