import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import '../../styles/services/services.css';
import IMG from '../../img/backgrounds/service-backgrounds/biohazard-cleaning.jpg';
import ReadyToGetKleaning from '../../components/Footer/readyToGetKleaning';

export default function Biohazard() {
    let [showWarning, setShowWarning] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <meta name="description" content="SparKlean offers biohazard cleaning services including bodily fluid cleanup, hoarding, junk removal, mold remediation and more!" />
                <meta name="keywords" content="SparKlean, service, biohazard, bio-hazardous, vomit, bodily fluids, blood, hazmat, personal protective equipment, ppe, masks" />
                <meta property="og:title" content="SparKlean | Bio Hazard Cleaning Services" />
                <meta property="og:description" content="SparKlean offers biohazard cleaning services including bodily fluid cleanup, hoarding, junk removal, mold remediation and more!" />
                <meta property="og:url" content="https://sparklean.services/biohazard" />
                <meta property="og:type" content="article" />
                <title>SparKlean | Bio-Hazard Cleaning</title>
            </Helmet>
            <section id="Service-Hero" data-service='biohazard'>
                <div className="container"
                    data-aos="fade-left">
                    <h1><span className="iconify icon" data-icon="fa-solid:biohazard"></span> Bio-Hazard</h1>
                    <h2>Viruses, Hoards & More</h2>
                </div>
            </section>

            { showWarning &&
                <div className="container alert alert-warning text-center my-5" style={ { fontSize: '16px' } }><span style={ { fontWeight: 800, fontSize: '110%' } }>NOTICE</span><br />If you have either <strong>consumed</strong> or been <strong>exposed</strong> to a hazardous chemical, poison, or toxin,
                    immediately call the 24-hour Poison Control Center Hotline: <a href="tel:+18002221222">1‑800‑222‑1222</a><br /><button className='btn' onClick={ () => { setShowWarning(false); } }>Dismiss</button></div>
            }

            <section id="Service">
                <div className="container">
                    <div className="desc"
                        data-aos="fade-left">
                        <img
                            data-aos="fade-right"
                            data-aos-delay="200"
                            src={ IMG }
                            alt="Biohazard Cleaning" />
                        <div className="cta">
                            <h2>For hazardous cleaning jobs,</h2>
                            <h3>we always use <strong>Personal Protective Equipment (PPE)</strong> such as gloves, hazmat suits, respirators, and shoe covers. Our staff is available 24/7 to provide emergency cleaning response when you need it most.</h3>
                            <ul className="selling-points">
                                <li>Quotes are 100% <strong>FREE</strong></li>
                                <li>100% Satisfaction Guarantee</li>
                                <li>Fully-customizable cleaning packages</li>
                            </ul>
                            <Link className="SPK-CTA"
                                to="/contact">Get a FREE Quote</Link>
                        </div>
                    </div>
                    <ul className="checklist" data-aos="zoom-in">
                        <li>
                            <iconify-icon icon="fa-solid:bacteria"></iconify-icon>
                            <p>Mold Remediation</p>
                        </li>
                        <li>
                            <iconify-icon icon="healthicons:loss-smell"></iconify-icon>
                            <p>Odor Removal</p>
                        </li>
                        <li><iconify-icon icon="fa6-solid:virus"></iconify-icon>
                            <p>Virus Elimination</p>
                        </li>
                        <li>
                            <iconify-icon icon="healthicons:blood-drop"></iconify-icon>
                            <p>Bodily Fluids</p>
                        </li>
                        <li><iconify-icon icon="charm:bug"></iconify-icon>
                            <p>Pest Treatment</p>
                        </li>
                        <li><iconify-icon icon="ic:outline-water-damage"></iconify-icon>
                            <p>Water Restoration</p>
                        </li>
                        <li><iconify-icon icon="radix-icons:crumpled-paper"></iconify-icon>
                            <p>Hoarding</p>
                        </li>
                        <li>
                            <p>and more...</p>
                        </li>
                    </ul>
                </div>
            </section>

            <ReadyToGetKleaning />
        </>
    );
}
