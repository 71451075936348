import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function NotFound() {
    return (
        <>
            <Helmet>
                <title>SparKlean | Page Not Found!</title>
                <meta name='robots' content='noindex, nofollow' />
            </Helmet>
            <div className="notFound container">
                <h1>Oh Snap<span style={ { color: 'hotpink' } }>!</span></h1>
                <h2>Page not found.</h2>
                <p>The content you're looking for may have been moved, or no longer exists.</p>
            </div>
        </>
    );
}
