import React from 'react';
import { useState } from 'react';

export default function GalleryImage({ ...img }) {
    const IMG = img;
    const [LOADED, SETLOADED] = useState(false);

    return (
        <>
            <iconify-icon icon="eos-icons:bubble-loading" style={ { fontSize: '50px', display: LOADED ? 'none' : 'block' } }></iconify-icon>
            <img src={ IMG.src } style={ { display: LOADED ? 'block' : 'none' } } onLoad={ () => { SETLOADED(true); } } alt='none' />

        </>
    );
}
