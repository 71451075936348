import { React, useState } from 'react';


export default function BeforeAfterWidget(props) {
    let [SliderPosition, setSliderPosition] = useState(25);

    return (
        <div className="ba-item" data-aos-delay={ props.delay }>
            <img className="before" src={ props.before } alt='' />
            <img className="after" src={ props.after } alt=''
                style={ { clipPath: `polygon(${SliderPosition}%0%,100%0%,100%100%,${SliderPosition}%100%)` } } />


            <input type="range" name="slider-cab" value={ SliderPosition } min="0" max="100"
                onInput={ (e) => { setSliderPosition(e.target.value); } } />
            <div className="strip" id="strip-slider-cab" style={ { left: SliderPosition + '%' } }></div>
        </div>
    );
}
