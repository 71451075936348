import React from 'react'
import '../styles/home/home.css'

export default function FAQ() {

    const FAQ = [
        {
            question: 'Is SparKlean a franchise or a local company?',
            answer: <p>SparKlean is a <strong>local cleaning company</strong> in <strong>Chattanooga, TN</strong>! We practice using recurring cleaners, and always prioritize your needs and schedule.</p>
        },
        {
            question: 'Do I need to be present during the cleaning process?',
            answer: <p><strong>No</strong>, but of course you can if you would like. When you make your booking,
                you'll be prompted to leave us entry instructions. However, if we cannot enter via your
                entry instructions, or you are not present to let us on the property, you may be charged a
                lock&#8209;out fee.</p>
        },
        {
            question: 'Is SparKlean licensed & insured?',
            answer: <p><strong>Absolutely</strong>, and we carry <strong>worker's compensation</strong>. Our
                cleaners are trained in safety protocols, but in the unlikely event of an accident or
                property damage, the cleaner will complete a report and our team will resolve the incident.
            </p>
        },
        {
            question: 'Do I need to provide cleaning supplies?',
            answer: <p><strong>No</strong>, we provide all necessary products and equipment to complete the job! All
                of our products are <strong>eco&#8209;safe</strong> and <strong>pet friendly</strong>, but
                if you prefer other products, we will absolutely accommodate your request.</p>
        },
        {
            question: 'Do you clean during or after business hours?',
            answer: <p><strong>Both!</strong> Our staff is ready to clean day or night, depending on your
                preference.</p>
        },
        {
            question: 'Is SparKlean pet-friendly?',
            answer: <p>We love animals! But, they don't always love us. If you think your pet may become anxious or
                defensive while we clean, we suggest making temporary arrangements for them while we clean
                your property. You can leave detailed pet instructions during your booking.</p>
        },
        {
            question: 'Can I request services with a notice less than 24 hours?',
            answer: <p>It's <strong>possible</strong>. Our team will try to accommodate any cleaning project, even
                last minute requests.</p>
        },
        {
            question: 'How do I make a payment? Do you accept cash, check or card?',
            answer: <p><strong>All of the above!</strong> Once your cleaning has been booked and you have approved
                your Estimate via email, you can let us know how you would like to pay. All of our online
                payments are conveniently handled through <strong>Invoice2Go</strong>.</p>
        },
        {
            question: 'Does SparKlean use recurring cleaners?',
            answer: <p><strong>Yes!</strong> We will send the <strong>same cleaner</strong> each time unless they are unavailable, at which point we will do everything we can to accommodate you!</p>
        },
    ]

    return (
        <section id="FAQ">
            <div className="container">
                <h1 data-aos="fade-down">Frequently Asked Questions</h1>
                <div className="decorative-line"></div>
            </div>

            <div className="accordion container" id="FAQ Accordion">

                {
                    FAQ.map((item, i) => {
                        return <div className='accordion-item' data-aos="fade-up" data-aos-offset={ 100 }>
                            <h2 className='accordion-header' id={ `flush-heading-${i}` }>
                                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target={ `#flush-collapse-${i}` } aria-expanded='false' aria-controls={ `flush-collapse-${i}` }>
                                    <span className='iconify-inline' data-icon='emokione-monotone:question-mark'></span>
                                    <p>{ item.question }</p>
                                </button>
                            </h2>

                            <div id={ `flush-collapse-${i}` } className="accordion-collapse collapse" aria-labelledby={ `flush-heading-${i}` }
                                data-bs-parent="#FAQ Accordion">
                                <div className="accordion-body"><span className="iconify" data-icon="twemoji:left-speech-bubble"></span>
                                    { item.answer }
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </section>
    )
}