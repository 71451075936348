import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import '../../styles/services/services.css';
import IMG from '../../img/backgrounds/service-backgrounds/commercial-combined.webp';
import ReadyToGetKleaning from '../../components/Footer/readyToGetKleaning';

export default function Commercial() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <meta name="description" content="SparKlean offers commercial cleaning services for offices, public buildings, and more." />
                <meta name="keywords" content="sparklean, commercial, cleaning, services, power washing, office, public, sanitize, disinfect, lobby, breakroom, chattanooga, ooltewah" />
                <meta property="og:title" content="SparKlean | Commercial Cleaning Services" />
                <meta property="og:description" content="SparKlean offers commercial cleaning services for offices, public buildings, and more." />
                <meta property="og:url" content="https://sparklean.services/commercial" />
                <meta property="og:type" content="article" />
                <title>SparKlean | Commercial Cleaning</title>
            </Helmet>
            <section id="Service-Hero" data-service='commercial'>
                <div className="container"
                    data-aos="fade-left">
                    <h1><span className="iconify icon" data-icon="ep:office-building"></span> Commercial</h1>
                    <h2>Office Buildings & More</h2>
                </div>
            </section>

            <section id="Service">
                <div className="container">
                    <div className="desc"
                        data-aos="fade-left">
                        <img
                            data-aos="fade-right"
                            data-aos-delay="200"
                            src={ IMG }
                            alt="Commercial Cleaning" />
                        <div className="cta">
                            <h2>We keep properties in perfect shape.</h2>
                            <h3>From schools to office buildings, gas stations and healthcare centers, we take pride in our ability to uphold clean work-environments. We offer extensive deep-cleaning solutions, including sanitation which kills bacteria & viruses such as influenza and Covid-19.</h3>
                            <ul className="selling-points">
                                <li>Quotes are 100% <strong>FREE</strong></li>
                                <li>100% Satisfaction Guarantee</li>
                                <li>Fully-customizable cleaning packages</li>
                            </ul>
                            <Link className="SPK-CTA"
                                to="/contact">Get a FREE Quote</Link>
                        </div>
                    </div>
                    <ul className="checklist" data-aos="zoom-in">
                        <li>
                            <iconify-icon icon="heroicons:building-office"></iconify-icon>
                            <p>Offices</p>
                        </li>
                        <li><iconify-icon icon="emojione-monotone:construction"></iconify-icon>
                            <p>New Constructions</p>
                        </li>
                        <li><iconify-icon icon="material-symbols:window-sensor-outline"></iconify-icon>
                            <p>Window Cleaning</p>
                        </li>
                        <li><iconify-icon icon="icon-park-outline:bubble-chart"></iconify-icon>
                            <p>Carpet Shampooing & Extraction</p>
                        </li>
                        <li><iconify-icon icon="icon-park-outline:floor-tile"></iconify-icon>
                            <p>Tile & Concrete Scrubbing</p>
                        </li>
                        <li><iconify-icon icon="fluent-emoji-high-contrast:sparkle"></iconify-icon>
                            <p>Floor Polishing & Buffing</p>
                        </li>
                        <li><iconify-icon icon="mdi:bacteria-outline"></iconify-icon>
                            <p>Disinfecting</p>
                        </li>
                        <li>
                            <Link to="/additional">and more!</Link>
                        </li>
                    </ul>
                </div>
            </section>

            <section id="Packages"
                className="my-5">
                <h1 className='mt-5'>Commercial Packages</h1>
                <div className="decorative-line mb-5"></div>
                <div className="container">

                    <div className="package-card"
                        data-aos="fade-up">
                        <div className="head"
                            style={ { backgroundColor: '#396379' } }>
                            <span className="iconify"
                                data-icon="twemoji:broom"></span>
                            <h3>Business</h3>
                            <h2>Basic Klean</h2>
                            <div className="decorative-line"></div>
                            <p>Limited Services</p>
                        </div>
                        <ul>
                            <li>Vacuum floors, carpets, corners</li>
                            <li>Mop, sanitize, rinse floors</li>
                            <li>Clean Bathrooms & Break Room</li>
                            <li>Empty all trash bins & replace liners</li>
                        </ul>
                        <div className="time">
                            <iconify-icon icon="bi:clock"></iconify-icon>
                            <div className="times">

                                <p>Weekly, Bi-Weekly, 3&nbsp;Weeks, 1&nbsp;Month</p>
                            </div>
                        </div>
                    </div>

                    <div className="package-card"
                        data-aos="fade-up"
                        data-aos-delay="100">
                        <div className="head"
                            style={ { background: 'linear-gradient(to top right, #005681, #007ab3)' } }>
                            <span className="iconify"
                                data-icon="noto:sponge"></span>
                            <h3>Business</h3>
                            <h2>Sanitation Klean</h2>
                            <div className="decorative-line"></div>
                            <p>Full-service</p>
                        </div>
                        <div className="popular">
                            <p>Most Popular</p>
                        </div>
                        <ul>
                            <li>Clean Front Entrance & Lobby</li>
                            <li>Clean, Sanitize and Restock Bathrooms</li>
                            <li>Mop, sanitize, rinse floors</li>
                            <li>Vacuum floors, rugs, furniture, corners</li>
                            <li>Empty all trash bins & replace liners</li>
                            <li>Dust furniture, appliances, surfaces</li>
                            <li>Spray sanitizer in each room, eliminating bacteria</li>
                        </ul>
                    </div>

                    <div className="package-card"
                        data-aos="fade-up"
                        data-aos-delay="200">
                        <div className="head"
                            style={ { background: 'linear-gradient(to top right,#2b6943, #2e9756)' } }>
                            <span className="iconify" data-icon="material-symbols:checklist-rtl-rounded"></span>
                            <h3>Business</h3>
                            <h2>Custom Klean</h2>
                            <div className="decorative-line"></div>
                            <p>Select Services</p>
                        </div>
                        <div className="container px-4 text-start">
                            <h2>Unsure about what you need?</h2>
                            <p style={ { fontSize: '18px', lineHeight: 1.2, marginBottom: 0, marginTop: '1rem' } }>Let one of our specialists visit the property and create a <strong>custom package</strong> that suits your needs.</p>
                            <p className='mt-4' style={ { fontSize: '18px', lineHeight: 1.2 } }>This package is seperate from the Basic and Sanitation packages, but can include some or all of the services from those packages, as well as:</p>
                        </div>
                        <ul>
                            <li>Tile & Concrete Scrubbing</li>
                            <li>Floor Waxing & Polishing</li>
                            <li>Marble & Terrazzo Refinishing</li>
                            <li>Carpet Shampooing & Extraction</li>
                            <li><i>and much more!</i></li>
                        </ul>
                        <Link to="/contact">
                            <p className="discount"><span className="iconify"
                                data-icon="ic:baseline-discount"></span> Custom Pricing</p>
                        </Link>
                    </div>
                </div>
            </section>

            <ReadyToGetKleaning />
        </>
    );
}
