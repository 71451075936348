import React from 'react';

const CallOrText = () => {
    return (
        <div className='callOrText'>
            <a href='tel:+14238001418'><span className="iconify-inline" data-icon="lucide:phone-call"></span> Call</a>
            <p>or</p>
            <a href="sms:+14238001418"><span className="iconify-inline" data-icon="icon-park-solid:message"></span> Text</a>
        </div>
    );
};

export default CallOrText;
