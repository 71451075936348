import React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

export default function Thanks() {
    let [name, setName] = useState("you're awesome");

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let name = params.get('name');
        if (name) { setName(name); }
    }, []);

    return (
        <>
            <Helmet>
                <title>SparKlean | Thank You!</title>
                <meta name='robots' content='noindex, nofollow' />
            </Helmet>
            <div style={ { minHeight: '50vh' } }>
                <div className='text-center mt-5'>
                    <iconify-icon style={ { fontSize: '40px' } } icon="emojione:white-heavy-check-mark"></iconify-icon>
                    <h1>Thanks, { name }!</h1>
                    <p style={ { fontSize: '1.2rem' } }>We'll reach out to you as soon as we can.</p>
                    <p style={ { fontSize: '1rem', opacity: .75 } }>You may close this tab, or keep browsing.</p>
                </div>
            </div>
        </>
    );
}
