const PAGES = [
    // Services Pages
    {
        enabled: true,
        name: "Residential Cleaning",
        desc: "Services / Residential Cleaning",
        type: "Webpage",
        href: "/residential",
        target: "",
        image: "",
        icon: "clarity:house-solid",
        keywords: ["residential", "home", "home cleaning", "house", "house cleaning", "apartment cleaning", "condominium", "owned", "hoarding", "garage", "roof cleaning", "clean", "cleaning", "pool cleaning", "move out cleaning", "move in cleaning", "general cleaning", "deep cleaning", "window clean", "disinfecting", "odor", "vacant", "mold remediation", "dryer vent", "cabinet", "closet", "carpet", "rug", "organization", "organizing", "declutter", "sanitation", "junk", "construction", "domestic", "hot tub", "swim spa", "appliance", "gutter", "upholstery", "pest"]
    },
    {
        enabled: true,
        name: "Rental Cleaning",
        desc: "Services / Rental Cleaning",
        type: "Webpage",
        href: "/rentals",
        target: "",
        image: "",
        icon: "jam:airbnb",
        keywords: ["residential", "home", "home cleaning", "house", "house cleaning", "apartment cleaning", "condominium", "owned", "rental", "hoarding", "garage", "clean", "cleaning", "pool cleaning", "move out cleaning", "move in cleaning", "general cleaning", "deep cleaning", "airbnb", "vrbo", "booking.com", "rent", "rental", "window clean", "disinfecting", "odor", "vacant", "mold remediation", "dryer vent", "cabinet", "closet", "carpet", "rug", "organization", "organizing", "declutter", "sanitation", "junk", "construction", "domestic", "hot tub", "swim spa", "appliance", "gutter", "upholstery", "pest"]
    },
    {
        enabled: true,
        name: "Commercial Cleaning",
        desc: "Services / Commercial",
        type: "Webpage",
        href: "/commercial",
        target: "",
        image: "",
        icon: "ep:office-building",
        keywords: ["commercial", "business", "business", "cleaning", "office cleaning", "spa", "station", "school", "facility", "church", "construction", "move", "disinfecting", "sanitization", "floor polishing", "floor restoration", "odor", "vacant", "general", "carpet", "hot tub", "appliance", "pest", "roof", "window"]
    },
    {
        enabled: false,
        name: "Bio-Hazard Treatment",
        desc: "Services / Biohazard",
        type: "Webpage",
        href: "/biohazard",
        target: "",
        image: "",
        icon: "fa-solid:biohazard",
        keywords: ["biohazard", "chemicals", "cleaning", "fluids", "mold remediation", "viruses", "hoarding", "poison", "toxin", "blood", "vomit", "poop", "feces", "commercial", "business", "hotline", "junk removal", "protective", "ppe", "hazmat", "decontamination", "emergency", "bug", "infestation", "bodily fluids", "odor removal", "decontamination", "water restoration", "water extraction"]
    },
    {
        enabled: true,
        name: "Additional Services",
        desc: "Services / Additional",
        type: "Webpage",
        href: "/additional",
        target: "",
        image: "",
        icon: "ri:service-line",
        keywords: ["additional", "more", "hoarding", "garage", "clean", "cleaning", "pool", "power wash pressure wash", "move out", "move in", "general", "deep", "airbnb", "air bnb", "vacant", "window", "disinfecting", "odor", "vacant", "mold", "dryer vent", "cabinet", "closet", "RV", "boat", "patio", "carpet", "rug", "organization", "sanitation", "junk", "construction", "post construction", "domestic", "hot tub", "swim spa", "appliance", "upholstery", "event", "party"]
    },
    {
        enabled: false,
        name: "Mold Remediation",
        desc: "Services / Mold",
        type: "Webpage",
        href: "/mold",
        target: "",
        image: "",
        icon: "fa6-solid:bacteria",
        keywords: ["mold", "fungi", "commercial", "business", "homes", "houses", "removal", "odor", "treatment"]
    },
    {
        enabled: false,
        name: "Power Washing",
        desc: "Services / Powerwashing",
        type: "Webpage",
        href: "/powerwashing",
        target: "",
        image: "",
        icon: "entypo:water",
        keywords: ["powerwashing", "pressure", "pools", "homes", "houses", "schools", "commercial", "construction", "rinse", "exterior", "driveway", "mold", "mildew", "concrete", "parking", "roof", "gutter"]
    },
    // Other Pages
    {
        enabled: true,
        name: "Contact Us",
        desc: "SparKlean / Contact",
        type: "Webpage",
        href: "/contact",
        target: "",
        image: "",
        icon: "clarity:talk-bubbles-solid",
        keywords: ["contact", "message", "complaint", "pay", "question", "answer", "call", "text", "communication", "email"],
    },
    {
        enabled: true,
        name: "Virus Policy",
        desc: "Policy / Viruses",
        type: "Article",
        href: "/policy/Covid-19",
        target: "_blank",
        image: "",
        icon: "ic:baseline-policy",
        keywords: ["virus", "covid-19", "policy", "statement", "influenza", "bacteria", "germs", "sick", "sanitization", "contamination"],
    },
    {
        enabled: true,
        name: "Privacy Policy",
        desc: "Policy / Privacy",
        type: "Article",
        href: "/policy/privacy",
        target: "_blank",
        image: "",
        icon: "fa-solid:user-shield",
        keywords: ["privacy", "policy", "terms", "protection", "website", "data"],
    },
    {
        enabled: true,
        name: "Terms of Use",
        desc: "Policy / Terms Of Use",
        type: "Article",
        href: "/policy/terms",
        target: "_blank",
        image: "",
        icon: "line-md:clipboard-list",
        keywords: ["policy", "terms", "use", "protection", "website", "data"],
    },
    {
        enabled: true,
        name: "Gallery",
        desc: "SparKlean / Gallery",
        type: "Webpage",
        href: "/gallery",
        target: "",
        image: "",
        icon: "bi:image-fill",
        keywords: ["gallery", "images", "pictures", "before", "after", "art", "artwork"],
    }
];

export default PAGES;