import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import '../../styles/services/services.css';
import House from '../../img/backgrounds/service-backgrounds/s-house.webp';
import ReadyToGetKleaning from '../../components/Footer/readyToGetKleaning';

export default function Residential() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <meta name="description" content="SparKlean offers residential cleaning services for homes, apartments, and more!" />
                <meta name="keywords" content="house cleaning service, chattanooga, residential, power washing, bedroom, kitchen, garage, roof, gutter, driveway, sanitize, disinfect, appliances" />
                <meta property="og:title" content="SparKlean | Residential Cleaning Services" />
                <meta property="og:description" content="SparKlean offers residential cleaning services for homes, apartments, and more!" />
                <meta property="og:url" content="https://sparklean.services/residential" />
                <meta property="og:type" content="article" />
                <title>SparKlean | Residential Cleaning</title>
            </Helmet>
            <section id="Service-Hero" data-service='residential'>
                <div className="container"
                    data-aos="fade-left">
                    <h1><span className="iconify icon"
                        data-icon="ci:home-alt-outline"></span> Residential</h1>
                    <h2>Homes & Apartments, Owned or Rented</h2>
                </div>
            </section>

            <section id="Service">
                <div className="container">
                    <div className="desc"
                        data-aos="fade-left">
                        <img
                            data-aos="fade-right"
                            data-aos-delay="200"
                            src={ House }
                            alt="House" />
                        <div className="cta">
                            <h2>Cleaning your home shouldn't be a chore.</h2>
                            <h3>SparKlean is here to help! Our process is
                                designed to keep your home looking its best and ensure proper sanitization and disinfecting.</h3>
                            <ul className="selling-points">
                                <li>Quotes are 100% <strong>FREE</strong></li>
                                <li>100% Satisfaction Guarantee</li>
                                <li>Fully-customizable cleaning packages</li>
                            </ul>
                            <Link className="SPK-CTA"
                                to="/contact">Get a FREE Quote</Link>
                        </div>

                    </div>
                    <ul className="checklist" data-aos="zoom-in">
                        <li>
                            <iconify-icon icon="bi:house-door"></iconify-icon>
                            <p>General House Cleaning</p>
                        </li>
                        <li>
                            <iconify-icon icon="ri:hotel-line"></iconify-icon>
                            <p>Apartment Cleaning</p>
                        </li>
                        <li>
                            <iconify-icon icon="healthicons:cleaning"></iconify-icon>
                            <p>Deep Cleaning</p>
                        </li>
                        <li>
                            <iconify-icon icon="material-symbols:window-sensor-outline"></iconify-icon>
                            <p>Window Cleaning</p>
                        </li>
                        <li><iconify-icon icon="mdi:file-cabinet"></iconify-icon>
                            <p>Cabinet Cleaning</p>
                        </li>
                        <li><iconify-icon icon="fluent:sparkle-16-regular"></iconify-icon>
                            <p>Organizing & De&nbsp;-&nbsp;cluttering</p>
                        </li>
                        <li><iconify-icon icon="mdi:bacteria-outline"></iconify-icon>
                            <p>Disinfecting</p>
                        </li>
                        <li>
                            <Link to="/additional">and more!</Link>
                        </li>
                    </ul>
                </div>
            </section>

            <section id="Packages"
                className="my-5">
                <h1 className='mt-5'>Residential Packages</h1>
                <div className="decorative-line mb-5"></div>
                <div className="container">

                    <div className="package-card"
                        data-aos="fade-up">
                        <div className="head"
                            style={ { backgroundColor: '#396379' } }>
                            <span className="iconify"
                                data-icon="twemoji:broom"></span>
                            <h2>Basic Klean</h2>
                            <div className="decorative-line"></div>
                            <p>Limited Services</p>
                        </div>
                        <ul>
                            <li>Vacuum floors, rugs, corners</li>
                            <li>Mop, sanitize, rinse floors</li>
                            <li>Clean & sanitize surface tops</li>
                            <li>Empty all trash bins & replace liners</li>
                        </ul>
                        <div className="time">
                            <iconify-icon icon="bi:clock"></iconify-icon>
                            <div className="times">

                                <p>Weekly, Bi-Weekly, 3&nbsp;Weeks, 1&nbsp;Month</p>
                            </div>
                        </div>
                    </div>

                    <div className="package-card"
                        data-aos="fade-up"
                        data-aos-delay="100">
                        <div className="head"
                            style={ { background: 'linear-gradient(to top right, #005681, #007ab3)' } }>
                            <span className="iconify"
                                data-icon="noto:sponge"></span>
                            <h2>Sanitation Klean</h2>
                            <div className="decorative-line"></div>
                            <p>Full-service</p>
                        </div>
                        <div className="popular">
                            <p>Most Popular</p>
                        </div>
                        <ul>
                            <li>Vacuum floors, rugs, furniture, corners</li>
                            <li>Mop, sanitize, rinse floors</li>
                            <li>Empty all trash bins & replace liners</li>
                            <li>Clean, sanitize and polish <strong>kitchen</strong> & <strong>bathrooms</strong></li>
                            <li>Dust furniture, appliances, surfaces</li>
                            <li>Tidy up all rooms including beds & sheets</li>
                            <li>Clean & polish mirrors and glass surfaces</li>
                            <li>Treat soap scum, mold, and hard water buildup</li>
                        </ul>
                    </div>

                    <div className="package-card"
                        data-aos="fade-up"
                        data-aos-delay="200">
                        <div className="head"
                            style={ { background: 'linear-gradient(to top right,#2b6943, #2e9756)' } }>
                            <span className="iconify" data-icon="fluent:text-bullet-list-square-settings-20-regular"></span>
                            <h2>Custom Klean</h2>
                            <div className="decorative-line"></div>
                            <p>Select Services</p>
                        </div>
                        <div className="container px-4 text-start">
                            <h2>Unsure about what you need?</h2>
                            <p style={ { fontSize: '18px', lineHeight: 1.2, marginBottom: 0, marginTop: '1rem' } }>Let one of our specialists visit the property and create a <strong>custom package</strong> that suits your needs.</p>
                            <p className='mt-5' style={ { fontSize: '18px', lineHeight: 1.2 } }>This package is seperate from the Basic and Sanitation packages, but can include some or all of the services from those packages!</p>
                        </div>
                        <Link to="/contact">
                            <p className="discount"><span className="iconify"
                                data-icon="ic:baseline-discount"></span> Custom Pricing</p>
                        </Link>
                    </div>
                </div>
            </section>

            <ReadyToGetKleaning />
        </>
    );
}
