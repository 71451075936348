import React from "react";
import { Link } from "react-router-dom";

export default function SearchResult({ page, tab }) {
    return (
        <Link to={ page.href } target={ page.target } className='result' tabIndex={ 0 } aria-label={ `Result ${tab}, ${page.desc}. Destination type: ${page.type}` } onClick={ () => { document.activeElement.blur(); } }>
            <div className="result-img">
                {/* Iconify Span elements were not displaying appropriately. Switched to iconify element tags. */ }
                <iconify-icon icon={ page.icon }></iconify-icon>
            </div>
            <div>
                <h1>{ page.name }</h1>
                <p>{ page.desc }</p>
                <p className="type">{ page.type }</p>
            </div>
        </Link>
    );
}
