import React from 'react';
import '../styles/home/home.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import TEAM from '../scripts/meetTheTeam';
import TeamMember from '../components/TeamMember/teamMember';
import BeforeAfter from '../components/Before-After/BeforeAfter';
import Video from '../videos/SparKlean-ad-v4.mp4';
import FAQ from '../components/FAQ';

const Home = () => {

    function moveSlider(slider, value) {
        let percentage = value + "%";
        // Grab the slider
        const SLIDER = document.getElementById("strip-" + slider);
        const AFTERIMG = document.getElementById("img-" + slider);

        // Set its position
        SLIDER.style.left = percentage;

        // Set the clipPath of the After Image
        AFTERIMG.style.clipPath = "Polygon(" +
            percentage + "0%" + "," +
            "100%" + "0%" + "," +
            "100%" + "100%" + "," +
            percentage + "100%" + ")";
    }

    return (
        <>
            <Helmet>
                <meta name="description"
                    content="SparKlean is a full-service cleaning company operating out of Chattanooga, Tennessee. We offer cleaning for residential homes, houses and apartments, commercial and biohazard cleaning, and much more!" />
                <meta name="keywords" content="sparklean, cleaning, services, house, home, residential, commercial, chattanooga, ooltewah, power washing, all-in-one" />
                <meta property="og:title" content="SparKlean | Cleaning Services" />
                <meta property="og:description" content="SparKlean is an all-in-one cleaning company that offers residential, commercial, biohazard and other cleaning services." />
                <meta property="og:type" content="website" />
                <title>SparKlean | Cleaning Services</title>
            </Helmet>
            <section id="Hero">
                <div className="container">
                    <h1>All-In-One</h1>
                    <h2>Cleaning & Product Supplier Services</h2>
                    <h3 data-aos='fade-right'><span>in</span> <iconify-icon inline icon="ci:location"></iconify-icon>Chattanooga, TN</h3>
                    <p data-aos='fade-up' data-aos-delay='250'>Keeping properties klean one spray at a time since&nbsp;2010.</p>
                    <Link className="btn-solid-lg" to="/contact" data-aos="flip-right" data-aos-delay="750" data-aos-offset="0">Get
                        A FREE Quote</Link>
                </div>
            </section>

            <div id="Banner" className="container">
                <a href="https://goo.gl/maps/3cRvKqbEz66mNaho9" target="_blank" rel='noreferrer' className="banner-box">
                    <span className="iconify" data-icon="flat-color-icons:google"></span>
                    <span className="iconify" data-icon="emojione:star"></span>
                    <span className="iconify" data-icon="emojione:star"></span>
                    <span className="iconify" data-icon="emojione:star"></span>
                    <span className="iconify" data-icon="emojione:star"></span>
                    <span className="iconify" data-icon="emojione:star"></span>
                    <p>5.0/5</p>
                </a>
                <div className="banner-box">
                    <a href="https://www.bbb.org/us/tn/chattanooga/profile/cleaning-services/sparklean-cleaning-services-0483-40131244"
                        target="_blank" rel='noreferrer'><img className='bbb' src="https://upload.wikimedia.org/wikipedia/commons/e/eb/Better_Business_Bureau.svg"
                            alt="Better Business Bureau Accredited" /></a>
                </div>
                <div className="banner-box">
                    <h2 className="stat"><strong id="Banner-count">10,000+</strong> Jobs<br />Completed</h2>
                </div>
            </div>

            <div className="container text-center py-5" data-aos="fade-up">
                <h1 style={ { fontFamily: "Dancing Script", fontWeight: 200 } }>It's not clean until it's <span style={ { fontWeight: 800, color: 'hotpink' } }>SparKlean</span> !</h1>
                <div className="decorative-line"></div>
            </div>

            <section id="About">
                <div className="container text-center d-none" data-aos="fade-down">
                    <h1>About Us</h1>
                    <div className="decorative-line white"></div>
                </div>
                <div className="container row mx-auto">
                    <summary className="text-box col-12 col-lg-6 p-2 p-lg-5 text-start" style={ { listStyle: 'none' } } data-aos="fade-right">
                        <h1>SparKlean<span>&trade;</span> is a unique company specializing in Detail/Sanitation procedures.</h1>
                        <p className="my-4">Our team of Professional Cleaners keep your home, business, or AirBnb/Vacation rentals looking its best!</p>
                        <p>We use eco-friendly products made locally.</p>
                        <p>HEPA Certified equipment and sanitation qualified in the laatest cleaning procedures that are proven to eliminate waste, dust, dirt, grime, and 99.9% of bacteria.</p>
                        <p>Protecting our customer's wellbeing and saving the planet are our top priorities.</p>
                        <div className="check">
                            <span>SERVICE</span>
                            <span>QUALITY and</span>
                            <span>VALUE you&nbsp;can&nbsp;depend&nbsp;on!</span>
                        </div>
                    </summary>
                    <div className="line d-lg-none"></div>
                    <div className="perks col-12 col-lg-6" data-aos="fade-left">
                        <div className="perk">
                            <span className="iconify" data-icon="bxs:store-alt"></span>
                            <p>Locally Owned & Operated</p>
                        </div>
                        <div className="perk">
                            <span className="iconify" data-icon="mdi:certificate-outline"></span>
                            <p>HEPA Certified</p>
                        </div>
                        <div className="perk">
                            <span className="iconify" data-icon="healthicons:ppe-face-mask"></span>
                            <p>PPE worn to prevent cross contamination</p>
                        </div>
                        <div className="perk">
                            <span className="iconify" data-icon="fluent:sanitize-20-filled"></span>
                            <p>Sanitation & Disinfection Procedures</p>
                        </div>
                        <div className="perk">
                            <span className="iconify" data-icon="mdi:spray-bottle"></span>
                            <p>High-quality Materials</p>
                        </div>
                        <div className="perk">
                            <span className="iconify" data-icon="carbon:camera-action"></span>
                            <p>GPS / Dash Cam Monitoring</p>
                        </div>
                        <div className="perk">
                            <span className="iconify" data-icon="ic:baseline-checklist"></span>
                            <p>Detailed Cleaning Procedures</p>
                        </div>
                        <div className="perk">
                            <span className="iconify" data-icon="eos-icons:cluster-management"></span>
                            <p>Scheduling Software</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container text-center" style={ { marginTop: 5 + 'vh', marginBottom: 5 + 'vh' } }>
                <h1>Our Services</h1>
                <div className="decorative-line"></div>
            </div>

            <section id="Services">
                <div className="service-grid">
                    <Link to="/residential" className="service-button" data-service='residential'
                        data-aos="fade">
                        <span className="iconify icon" data-icon="ci:home-alt-outline"></span>
                        <h2>Residential</h2>
                        <p>Homes & Apartments</p>
                        <span className="iconify blink" data-icon="mdi:gesture-double-tap"></span>
                    </Link>
                    <Link to="/commercial" className="service-button" data-service='commercial'
                        data-aos="fade">
                        <span className="iconify icon" data-icon="ep:office-building"></span>
                        <h2>Commercial</h2>
                        <p>Offices & More</p>
                        <span className="iconify blink" data-icon="mdi:gesture-double-tap"></span>
                    </Link>
                    <Link to="/rentals" className="service-button" data-service='rentals'
                        data-aos="fade">
                        <span className="iconify icon" data-icon="jam:airbnb"></span>
                        <h2>Airbnb/Vacation</h2>
                        <p>Rentals</p>
                        <span className="iconify blink" data-icon="mdi:gesture-double-tap"></span>
                    </Link>
                    <Link to="/additional" className="service-button" data-service='additional'
                        data-aos="fade">
                        <span className="iconify icon" data-icon="ri:service-line"></span>
                        <h2>Additional</h2>
                        <p>View List of Services</p>
                        <span className="iconify blink" data-icon="mdi:gesture-double-tap"></span>
                    </Link>
                </div>
            </section>

            <section id='Video'>
                <video controls id='Video' preload='metadata' poster='images/og-image.png'>
                    <source src={ Video } type='video/mp4' />
                </video>
            </section>

            <section id="Before-After" aria-hidden="true" className='d-none'>
                <div className="container">
                    <h1 data-aos="fade-down">Before & After!</h1>
                    <h2 data-aos="fade" style={ { fontSize: '80%' } }>Swipe left & right</h2>
                    <div data-aos="fade-up" className="decorative-line"></div>
                </div>
                <div className="contain" data-aos="fade">
                    <div className="ba-item" data-aos-delay="" tabIndex='-1' aria-hidden="true">
                        <div className="before" style={ { backgroundImage: 'url(images/backgrounds/Before-Afters/floor-b.jpg)' } }>
                        </div>
                        <div className="after" id="img-slider-cab"
                            style={ { backgroundImage: 'url(images/backgrounds/Before-Afters/floor-a.jpg)', clipPath: 'polygon(var(--default-slider-pos)0%,100%0%,100%100%,var(--default-slider-pos)100%)' } }>
                        </div>
                        <input aria-hidden='true' tabIndex='-1' style={ { zIndex: -1 } } type="range" name="slider-cab" value="25" min="0" max="100"
                            onInput={ (e) => { moveSlider(e.target.name, e.target.value); } } />
                        <div className="strip" id="strip-slider-cab"></div>
                    </div>
                    <div className="ba-item" data-aos-delay="200" tabIndex='-1' aria-hidden='true'>
                        <div className="before" style={ { backgroundImage: 'url(images/backgrounds/Before-Afters/closet-b.jpg' } }>
                        </div>
                        <div className="after" id="img-slider-closet"
                            style={ { backgroundImage: 'url(images/backgrounds/Before-Afters/closet-a.jpg)', clipPath: 'polygon(var(--default-slider-pos)0%,100%0%,100%100%,var(--default-slider-pos)100%)' } }>
                        </div>
                        <input aria-hidden='true' tabIndex={ -1 } style={ { zIndex: -1 } } type="range" name="slider-closet" value="25" min="0" max="100"
                            onInput={ (e) => { moveSlider(e.target.name, e.target.value); } } />
                        <div className="strip" id="strip-slider-closet"></div>
                    </div>
                    <div className="ba-item" data-aos-delay="400" tabIndex='-1' aria-hidden='true'>
                        <div className="before" style={ { backgroundImage: 'url(images/backgrounds/Before-Afters/shower-b.jpg)' } }>
                        </div>
                        <div className="after" id="img-slider-kitchen"
                            style={ { backgroundImage: 'url(images/backgrounds/Before-Afters/shower-a.jpg)', clipPath: 'polygon(var(--default-slider-pos)0%,100%0%,100%100%,var(--default-slider-pos)100%)' } }>
                        </div>
                        <input aria-hidden='true' tabIndex={ -1 } style={ { zIndex: -1 } } type="range" name="slider-kitchen" value="25" min="0" max="100"
                            onInput={ (e) => { moveSlider(e.target.name, e.target.value); } } />
                        <div className="strip" id="strip-slider-kitchen"></div>
                    </div>
                </div>
            </section >

            <section id="Satisfaction">
                <div className="container">
                    <div className="row w-100 m-0">
                        <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
                            <div data-aos="fade-right">
                                <span className="iconify mx-auto" data-icon="clarity:shield-check-solid"
                                    style={ { fontSize: 60 + 'px' } }></span>
                                <h2>We've got you covered.</h2>
                            </div>
                            <div className="decorative-line" data-aos="fade-left"></div>
                            <p data-aos="fade-up">If you're not 100% satisfied with our cleaning, we'll come back to make it
                                right.</p>
                        </div>
                        <div className="col-12 col-lg-6" data-aos="fade-left">
                            <img loading="lazy" className='ribbon' src='/images/satisfaction-ribbon-lg.png' alt="Satisfaction Guaranteed" />
                        </div>
                    </div>
                </div>
            </section>

            <BeforeAfter />

            <FAQ />

            <section id="Contact">
                <div className="container">
                    <h1 data-aos="fade-down">All&nbsp;set? Let's&nbsp;get&nbsp;Kleaning!</h1>
                    <div data-aos="fade-up" data-aos-offset="0" className="decorative-line mb-5"></div>
                    <Link data-aos="fade-up" data-aos-offset="0" to="/contact">Contact us now!</Link>
                </div>
            </section>

            <iframe defer loading="lazy" title="Our Location - Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52279.86042282139!2d-85.21290747016481!3d35.01943776717467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886067329f22622b%3A0x4c54b5bbb2da70e7!2sSparklean%20Cleaning%20Services!5e0!3m2!1sen!2sus!4v1652676787685!5m2!1sen!2sus"
                width="100%" height="500px" style={ { border: 0, display: 'block' } } allowFullScreen=""
                referrerPolicy="no-referrer-when-downgrade"></iframe>

            <section id="Meet-The-Team">
                <h1 className='my-0'>Meet The Team</h1>
                <h2 style={ { fontWeight: 200, fontSize: '1.2em' } }>Behind the <span style={ { fontWeight: '400' } }>Klean</span></h2>
                <div className="decorative-line mb-5"></div>
                <div className="container">
                    {
                        TEAM.map((person, i) => {
                            return <TeamMember key={ i } name={ person.name } job={ person.job } image={ person.image } website={ person.website } socials={ person.socials } />;
                        })
                    }
                </div>
            </section>
        </>
    );
};

export default Home;
