import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import '../styles/contact/contact.css';

export default function Apply() {
    let [name, setName] = useState('');
    let [phone, setPhone] = useState('');
    let [email, setEmail] = useState('');
    let [message, setMessage] = useState('');
    let [hasResume, setHasResume] = useState(false);

    let allValid = () => {
        if (name && message && (phone || email)) { return true; }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function formatPhone(number) {
        console.log("Received", number);
        if (!number) { return number; }
        const filtered = number.replace(/[^\d.]/g, "");
        console.log("filtered to", filtered);
        if (filtered.length < 4) { return filtered; }
        if (filtered.length < 7) {
            return `${filtered.slice(0, 3)} ${filtered.slice(3)}`;
        }
        if (filtered.length === 7) {
            return `${filtered.slice(0, 3)} - ${filtered.slice(3)}`;
        }
        return `(${filtered.slice(0, 3)}) ${filtered.slice(3, 6)}-${filtered.slice(6, 10)}`;
    }

    return (
        <>
            <Helmet>
                <meta name="description" content="SparKlean Application Form " />
                <meta name="keywords" content="SparKlean, contact, apply, application, jobs, careers, employee" />
                <meta property="og:title" content="SparKlean | Apply Now" />
                <meta property="og:description" content="SparKlean Application Form" />
                <meta property="og:url" content="https://sparklean.services/apply" />
                <title>SparKlean | Contact Us</title>
            </Helmet>
            <section>
                <div className='d-flex flex-column align-items-center my-5'>
                    <h1>Apply Now</h1>
                    <div className="decorative-line"></div>
                    <p>Ready to start a career with SparKlean?</p>
                    <p>Let's get started!</p>
                    <div className="language-box d-none">
                        <p>EN</p>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                        </div>
                        <p>ES</p>
                    </div>
                </div>

                <div className="container">
                    <form id='Application-Form' className="row g-2 text-start mx-auto my-5" style={ { maxWidth: '700px' } }>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-floating mb-3">
                                    <input type="text" name='First-Name' className="form-control" id="firstName" placeholder="First Name" />
                                    <label htmlFor="firstName">First Name</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-floating mb-3">
                                    <input type="text" name='Last-Name' className="form-control" id="lastName" placeholder="Last Name" />
                                    <label htmlFor="lastName">Last Name</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-floating mb-3">
                                    <input type="text" name='Email' className="form-control" id="email" placeholder="Email" />
                                    <label htmlFor="email">Email</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-floating mb-3">
                                    <input type="text" name='Phone' className="form-control" id="phone" placeholder="Phone Number" />
                                    <label htmlFor="phone">Phone #</label>
                                </div>
                            </div>
                        </div>
                        <div className="decorative-line"></div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-floating mb-3">
                                    <input type="text" name='Address' className="form-control" id="address" placeholder="123 SparKlean Ave, Chattanooga, TN 37421" />
                                    <label htmlFor="address">Full Address</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                !hasResume &&
                                <p style={ { fontSize: '1.2rem' } }>If you have a resume, you can upload it here and submit your application. If you don't have a resume, please click <strong>Continue</strong>.</p>
                            }
                            <div className="mb-4">
                                <label htmlFor="formFile" className="form-label">Upload Resume&nbsp;
                                    {
                                        hasResume &&
                                        <iconify-icon icon="emojione:white-heavy-check-mark"></iconify-icon>
                                    }
                                </label>
                                <input className="form-control" type="file" id="formFile" onChange={ (e) => { setHasResume(e.target.value); } } />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label htmlFor="Authorized-To-Work">Are you authorized to work in the US?</label>
                                <select name='Authorized-To-Work' className="form-select form-select-sm" aria-label=".form-select-sm example">
                                    <option value="Unanswered">-</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="Background-Check">Are you willing to complete a background check?</label>
                                <select name='Background-Check' className="form-select form-select-sm" aria-label=".form-select-sm example">
                                    <option value="Unanswered">-</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label htmlFor="Has-Vehicle">Do you have a reliable personal vehicle?</label>
                                <select name='Has-Vehicle' className="form-select form-select-sm" aria-label=".form-select-sm example">
                                    <option value="Unanswered">-</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="Has-Car-Insurance">Do you have car insurance?</label>
                                <select name='Has-Car-Insurance' className="form-select form-select-sm" aria-label=".form-select-sm example">
                                    <option value="Unanswered">-</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <label style={ { fontSize: '1rem' } } htmlFor="message">Do you have a message for the hiring manager? <span>(Optional)</span></label>
                                <textarea className='form-control' name="message" id="message" cols="30" rows="5"></textarea>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-12">
                                {
                                    hasResume &&
                                    <button type='submit' className="btn btn-success w-auto">Submit Application <iconify-icon inline icon="bxs:paper-plane"></iconify-icon></button>
                                }
                                {
                                    !hasResume &&
                                    <button type='button' className="btn btn-primary w-auto">Continue</button>
                                }
                            </div>
                        </div>
                    </form>
                </div>

            </section >
        </>
    );
}
