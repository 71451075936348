import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="512"
            height="430"
            version="1.1"
            viewBox="0 0 512 430"
            xmlSpace="preserve"
        >
            <defs>
                <linearGradient id="linearGradient1842">
                    <stop offset="0" stopColor="#133768" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#1e79de" stopOpacity="1"></stop>
                </linearGradient>
                <linearGradient id="linearGradient1051">
                    <stop offset="0" stopColor="#133667" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#1e79de" stopOpacity="1"></stop>
                </linearGradient>
                <linearGradient
                    id="linearGradient1053"
                    x1="67.277"
                    x2="203.272"
                    y1="152.006"
                    y2="23.522"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1051"
                ></linearGradient>
                <linearGradient
                    id="linearGradient1844"
                    x1="388.201"
                    x2="200.817"
                    y1="242.588"
                    y2="403.193"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1842"
                ></linearGradient>
            </defs>
            <g
                fillOpacity="1"
                strokeLinecap="round"
                strokeMiterlimit="20"
                strokeWidth="59.904"
                paintOrder="stroke fill markers"
            >
                <path
                    fill="url(#linearGradient1053)"
                    d="M9.447 186.786c-3.454-12.724-7.266-26.284-7.266-51.914 0-25.63 11.088-74.888 44.533-102.699C64.752 17.174 96.265.87 125.011.386c25.16-.424 45.805 3.05 60.484 9.125 46.972 19.44 66.618 43.02 66.618 43.02s-38.717 7.998-57.439 20.54c-18.722 12.542-44.533 38.353-53.258 56.348-8.725 17.995-16.884 50.013-15.168 61.178 1.717 11.166-28.257 14.62-55.523 14.438-29.628-.181-58.188-7.161-61.278-18.25z"
                ></path>
                <path
                    fill="url(#linearGradient1844)"
                    d="M326.733 202.126s12.778-3.74 16.57 2.108c3.791 5.848 2.057 11.889-6.213 21.795-8.27 9.906-34.788 40.027-73.347 68.304s-59.38 39.587-82.26 46.27c-22.877 6.684-29.882 7.295-35.345 7.198-5.462-.096-13.527-.674-13.527-.674s19.893 20.409 85.875 69.304c13.328 9.877 16.08 12.987 27.584 12.794 11.504-.193 18.637-6.684 21.657-9.19 3.02-2.506 57.453-47.042 100.125-83.544 42.672-36.503 81.245-71.885 81.245-71.885s16.557-62.463-36.228-82.739c-52.786-20.275-86.136 20.26-86.136 20.26z"
                ></path>
                <path
                    fill="#1e79de"
                    d="M9.447 186.786s44.72 22.975 80.347 14.977c35.626-7.998 53.076-14.178 92.702-57.802 39.625-43.625 96.7-106.88 165.045-125.42 68.345-18.541 99.61-2.545 119.604 8.724 19.994 11.27 44.054 39.816 44.599 85.258.545 45.442-17.64 113.222-62.647 152.083 0 0 5.641-78.752-48.598-85.95-54.24-7.197-83.288 34.703-138.298 76.604-55.011 41.9-96.912 70.177-128.53 68.892-23.067-.938-37.511-10.52-52.697-25.706-14.786-14.786-37.458-42.14-54.24-71.206-8.2-14.202-13.106-27.003-17.287-40.454z"
                ></path>
            </g>
        </svg>
    );
}

export default Icon;
