import React from 'react';

export default function TeamMember(props) {
    const PERSON = { ...props };
    return (
        <div className='TeamMember'>
            <img src={ process.env.PUBLIC_URL + PERSON.image } alt={ PERSON.name } loading='lazy' />
            <h1>{ PERSON.name }</h1>

            {
                Array.isArray(PERSON.job) ?
                    PERSON.job.map((item, i) => { return < h2 key={ i }>{ item }</h2>; }) :
                    <h2>{ PERSON.job }</h2>
            }

            { PERSON.website &&
                <a className='website' target='_blank' rel='noreferrer' href={ `https://${PERSON.website}` } title={ PERSON.website }>{ PERSON.website }</a>
            }
            <div className="socials container">
                { PERSON.socials &&
                    Object.keys(PERSON.socials).map((key, i) => {
                        switch (key) {
                            case "facebook":
                                return <a key={ i } href={ `https://www.facebook.com/${PERSON.socials[key]}` } target="_blank" rel='noreferrer' title={ `${PERSON.name}'s Facebook Page` }><iconify-icon icon="fa6-brands:facebook-f"></iconify-icon></a>;
                            case "twitter":
                                return <a key={ i } href={ `https://www.twitter.com/${PERSON.socials[key]}` } target="_blank" rel='noreferrer' title={ `${PERSON.name}'s Twitter Page` }><iconify-icon icon="bi:twitter"></iconify-icon></a>;
                            case "instagram":
                                return <a key={ i } href={ `https://www.instagram.com/${PERSON.socials[key]}` } target="_blank" rel='noreferrer' title={ `${PERSON.name}'s Instagram Page` }><iconify-icon icon="akar-icons:instagram-fill"></iconify-icon></a>;
                            case "linkedin":
                                return <a key={ i } href={ `https://www.linkedin.com/in/${PERSON.socials[key]}` } target="_blank" rel='noreferrer' title={ `${PERSON.name}'s LinkedIn Page` }><iconify-icon icon="akar-icons:linkedin-fill"></iconify-icon></a>;
                            case "tiktok":
                                return <a key={ i } href={ `https://www.tiktok.com/${PERSON.socials[key]}` } target="_blank" rel='noreferrer' title={ `${PERSON.name}'s TikTok Page` }><iconify-icon icon="akar-icons:tiktok-fill"></iconify-icon></a>;
                            case "snapchat":
                                return <a key={ i } href={ `https://www.snapchat.com/add/${PERSON.socials[key]}` } target="_blank" rel='noreferrer' title={ `${PERSON.name}'s Snapchat Profile` }><iconify-icon icon="fa:snapchat-ghost"></iconify-icon></a>;
                            case "website":
                                return <a key={ i } href={ PERSON.socials[key] } target="_blank" rel='noreferrer' title={ `${PERSON.name}'s Website` }><iconify-icon icon="mdi:web"></iconify-icon></a>;
                            case "github":
                                return <a key={ i } href={ `https://www.github.com/${PERSON.socials[key]}` } target="_blank" rel='noreferrer' title={ `${PERSON.name}'s GitHub Profile` }><iconify-icon icon="akar-icons:github-fill"></iconify-icon></a>;
                            default:
                                break;
                        }
                    })
                }
            </div>
        </div>
    );
}
