import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import '../../styles/services/services.css';
import Apartment from '../../img/backgrounds/service-backgrounds/rental-apartment.jpg';
import ReadyToGetKleaning from '../../components/Footer/readyToGetKleaning';

export default function Residential() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <meta name="description" content="SparKlean offers rental cleaning services for homes, apartments, and more!" />
                <meta name="keywords" content="rental, airbnb, house cleaning service, chattanooga, residential, power washing, bedroom, kitchen, garage, driveway, sanitize, disinfect, appliances" />
                <meta property="og:title" content="SparKlean | Rental Cleaning Services" />
                <meta property="og:description" content="SparKlean offers rental cleaning services for airbnbs, homes, apartments, and more!" />
                <meta property="og:url" content="https://sparklean.services/rentals" />
                <meta property="og:type" content="article" />
                <title>SparKlean | Rental Cleaning</title>
            </Helmet>
            <section id="Service-Hero" data-service='rentals'>
                <div className="container"
                    data-aos="fade-left">
                    <h1><span className="iconify icon"
                        data-icon="jam:airbnb"></span> Rentals</h1>
                    <h2>Airbnbs, Rental Homes & Apartments</h2>
                </div>
            </section>

            <section id="Service">
                <div className="container">
                    <div className="desc"
                        data-aos="fade-left">
                        <img
                            data-aos="fade-right"
                            data-aos-delay="200"
                            src={ Apartment }
                            alt="House" />
                        <div className="cta">
                            <h2>We'll maintain your rental property.</h2>
                            <h3>Leasing out an Airbnb? We'll handle the reset between bookings. Our process is designed to keep your rental property looking its best and ensure proper sanitization and disinfecting.</h3>
                            <h3>Hire us just for cleaning, or have SparKlean manage all your Airbnb needs from cleaning, lawn care, repairs and more. Ask us about our monthly management and other fees.</h3>
                            <ul className="selling-points">
                                <li>Quotes are 100% <strong>FREE</strong></li>
                                <li>100% Satisfaction Guarantee</li>
                                <li>Fully-customizable cleaning packages</li>
                            </ul>
                            <Link className="SPK-CTA"
                                to="/contact">Get a FREE Quote</Link>
                        </div>

                    </div>
                    <ul className="checklist" data-aos="zoom-in">
                        <li>
                            <iconify-icon icon="teenyicons:building-solid"></iconify-icon>
                            <p>Property Management</p>
                        </li>
                        <li>
                            <iconify-icon icon="wpf:maintenance"></iconify-icon>
                            <p>Maintenance & Repairs</p>
                        </li>
                        <li>
                            <iconify-icon icon="mdi:lawn"></iconify-icon>
                            <p>Lawn Care</p>
                        </li>
                        <li>
                            <iconify-icon icon="mdi:paper-towels"></iconify-icon>
                            <p>Restock Supply & Delivery</p>
                        </li>
                        <li>
                            <iconify-icon icon="fluent-emoji-high-contrast:sponge"></iconify-icon>
                            <p>Deep Cleaning</p>
                        </li>
                        <li>
                            <iconify-icon icon="material-symbols:window-sensor-outline"></iconify-icon>
                            <p>Window Cleaning</p>
                        </li>
                        <li><iconify-icon icon="mdi:file-cabinet"></iconify-icon>
                            <p>Cabinet Cleaning</p>
                        </li>
                        <li><iconify-icon icon="mdi:puddle"></iconify-icon>
                            <p>Stain Removal</p>
                        </li>
                        <li><iconify-icon icon="fluent:couch-24-filled"></iconify-icon>
                            <p>Upholstery Care</p>
                        </li>
                        <li><iconify-icon icon="fluent:sparkle-16-regular"></iconify-icon>
                            <p>Organizing & De&nbsp;-&nbsp;cluttering</p>
                        </li>
                        <li><iconify-icon icon="mdi:bacteria-outline"></iconify-icon>
                            <p>Disinfecting</p>
                        </li>
                        <li>
                            <Link to="/additional">and more!</Link>
                        </li>
                    </ul>
                </div>
            </section>

            <ReadyToGetKleaning />
        </>
    );
}
