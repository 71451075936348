import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import '../styles/gallery/gallery.css';
import GalleryImage from '../components/GalleryImage';

export default function Gallery() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function importAll(r) { return r.keys().map(r); }
    const images = importAll(require.context('../../public/images/gallery/', false, /\.(png|jpe?g|svg|webp)$/));

    return (
        <>
            <Helmet>
                <meta name="description" content="Our gallery! View pictures of our work." />
                <meta name="keywords" content="SparKlean, gallery, pictures, images, work, evidence, before and after" />
                <meta property="og:title" content="SparKlean | Gallery" />
                <meta property="og:description" content="Our gallery! View pictures of our work." />
                <meta property="og:url" content="https://sparklean.services/gallery" />
                <meta property="og:type" content="article" />
                <title>SparKlean | Gallery</title>
            </Helmet>
            <section id="Gallery">
                <div data-aos='fade-up' className="text-center pb-5"
                    style={ { margin: 'max(2vh, 80px) 3vh 0 3vh' } }>
                    <h1 className="mb-5"
                        style={ { fontWeight: 300 } }>Our <span style={ { fontFamily: 'Dancing Script', fontSize: 150 + "%" } }>Gallery</span>
                    </h1>
                    <h2>We&nbsp;love&nbsp;what we&nbsp;do. Take&nbsp;a&nbsp;look!</h2>
                    <div className="decorative-line"></div>
                </div>

                <div id="Wall">
                    { images.map((img, i) => { return <GalleryImage src={ img } key={ i } alt='none' />; }) }
                </div>

                <div className="contact container"
                    data-aos="fade-up">
                    <span style={ { fontSize: 40 + 'px' } }
                        className="iconify"
                        data-icon="emojione:camera-with-flash"></span>
                    <h2>Like what you see?</h2>
                    <Link to="/contact"
                        className="SPK-CTA mt-3">Tell us about it!</Link>
                </div>
            </section >
        </>
    );
}
