import React from 'react';
import './beforeAfter.css';
import BeforeAfterWidget from './widget/BeforeAfterWidget';

export default function BeforeAfter() {

    function moveSlider(slider, value) {
        let percentage = value + "%";
        // Grab the slider
        const SLIDER = document.getElementById("strip-" + slider);
        const AFTERIMG = document.getElementById("img-" + slider);

        // Set its position
        SLIDER.style.left = percentage;

        // Set the clipPath of the After Image
        AFTERIMG.style.clipPath = "Polygon(" +
            percentage + "0%" + "," +
            "100%" + "0%" + "," +
            "100%" + "100%" + "," +
            percentage + "100%" + ")";
    }

    return (
        <section id="Before-After" aria-hidden="true">
            <div className="container">
                <h1 data-aos="fade-down">Before & After!</h1>
                <h2 data-aos="fade" style={ { fontSize: '80%' } }>Swipe left & right</h2>
                <div data-aos="fade-up" className="decorative-line"></div>
            </div>
            <div className="contain" data-aos="fade">

                <BeforeAfterWidget delay={ 0 } before='/images/backgrounds/Before-Afters/floor-b.jpg' after='/images/backgrounds/Before-Afters/floor-a.jpg' />

                <BeforeAfterWidget delay={ 200 } before='/images/backgrounds/Before-Afters/fridge-b.jpg' after='/images/backgrounds/Before-Afters/fridge-a.jpg' />

                <BeforeAfterWidget delay={ 400 } before='/images/backgrounds/Before-Afters/shower-b.jpg' after='/images/backgrounds/Before-Afters/shower-a.jpg' />

            </div>
        </section>
    );
}
