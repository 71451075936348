import React from 'react'
import { Link } from 'react-router-dom'

export default function ReadyToGetKleaning() {
    return (
        <div className="contact container"
            data-aos="fade-up">
            <h2>Ready to get Kleaning?</h2>
            <Link to="/contact"
                className="SPK-CTA mt-3">Contact Now</Link>
        </div>
    )
}
